<div
  class="hr_collapsible_uaq"
  *ngIf="eStatus.phase !== 'post-live'"
  (click)="toggleUserQuestions()"
>
  <button type="button" class="hr_btn_collapsible">
    <span
      [ngClass]="{
        active: this.showQuestionForm == 'in'
      }"
      class="arrow"
    >
      <span></span>
      <span></span>
    </span>
    <span
      >{{ eLangGlob.translateYour }} {{ eLangGlob.translateQuestions }}</span
    >
  </button>
</div>
<div
  *ngIf="eStatus.user_ask_questions"
  class="hr_collapsed_uaq"
  [@slideInOut]="showQuestionForm"
>
  <div style="margin-top: 20px">
    <div *ngIf="eStatus">
      <form
        style="max-width: 560px; display: block"
        name="form"
        (ngSubmit)="onSubmit()"
        #f="ngForm"
        ngNativeValidate
      >
        <span>{{ eLangGlob.translateUaqHeadlineLabel }}</span>
        <div class="hr_uaq_grid">
          <label class="hr_lbl_author" for="author">
            {{ eLangGlob.translateNameLabel }}
          </label>
          <input
            class="hr_uaq_textbox hr_uaq_input_author"
            type="text"
            name="author"
            [(ngModel)]="form.author"
            *ngIf="!eStatus.nameReq"
            #author="ngModel"
          />
          <input
            class="hr_uaq_textbox hr_uaq_input_author"
            type="text"
            name="author"
            [(ngModel)]="form.author"
            required
            *ngIf="eStatus.nameReq"
            #author="ngModel"
          />
          <label
            class="hr_lbl_location"
            for="location"
            *ngIf="eStatus.additional"
          >
            {{ eLangGlob.translateAdditionalLabel }}
          </label>
          <input
            class="hr_uaq_textbox hr_uaq_input_location"
            type="text"
            name="location"
            [(ngModel)]="form.location"
            required
            *ngIf="eStatus.additional && eStatus.additional_req"
            #location="ngModel"
          />
          <input
            class="hr_uaq_textbox hr_uaq_input_location"
            type="text"
            name="location"
            [(ngModel)]="form.location"
            *ngIf="eStatus.additional && !eStatus.additional_req"
            #location="ngModel"
          />
          <label class="hr_lbl_message" for="msgText">
            {{ eLangGlob.translateMsgLabel }}
          </label>
          <textarea
            class="hr_uaq_textbox hr_uaq_text_message"
            type="text"
            name="msgText"
            [(ngModel)]="form.msgText"
            required
            minlength="1"
            #msgText="ngModel"
          ></textarea>
          <span class="uaq_button_left"></span>
          <button
            [disabled]="showHint"
            class="hr_uaq_grid_submit hr_uaq_grid_button"
          >
            {{ eLangGlob.translateSendBtn }}
          </button>
          <span class="uaq_button_right"></span>
        </div>
        <div>
          <div
            class="ui visible warning message"
            role="alert"
            *ngIf="f.submitted && showHint"
            style="margin-top: 20px"
          >
            {{ hintText }}
          </div>
        </div>
          <p *ngIf="!this.eConfig.customPolicyAdditional_enabled && !this.eConfig.customPolicyName_enabled && !this.eConfig.customPolicyMessage_enabled" class="privacy_policy">{{ eLangGlob.translatePolicy }}</p>
          <p *ngIf="this.eConfig.customPolicyName_enabled" class="privacy_policy">{{ eLangGlob.translatePolicyName }}</p>
          <p *ngIf="this.eConfig.customPolicyAdditional_enabled && this.eStatus.additional" class="privacy_policy">{{ eLangGlob.translatePolicyAdditional }}</p>
          <p *ngIf="this.eConfig.customPolicyMessage_enabled" class="privacy_policy">{{ eLangGlob.translatePolicyMessage }}</p>
      </form>
    </div>
  </div>
</div>
<hr *ngIf="eStatus.phase !== 'post-live'" />
