import { Component, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { Eventconfig } from '../shared/eventconfig';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { Eventstatus } from '../shared/eventstatus';
import { LogService } from '../shared/log.service';
import { QuestionStoreService } from '../shared/question-store.service';

@Component({
  selector: 'lse-user-ask-question',
  templateUrl: './user-ask-question.component.html',
  styleUrls: ['./user-ask-question.component.css'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1.5s ease-in', style({ height: 0, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 0, opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          overflow: 'hidden',
          height: '*',
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out')),
    ]),
  ],
})
export class UserAskQuestionComponent implements OnInit {
  form: any = {};
  author: string;
  location: string;
  msgText: string;
  eLang = 'de';
  eLangGlob: any;
  hintText: string;
  eConfig: Eventconfig;
  eStatus: Eventstatus;
  dataIntervalTimer: any;
  showQuestionForm = 'out';

  showHint = false;
  showHintTimeout: any;

  errorMessage = 'An error has ocurred';

  constructor(
    private qs: QuestionStoreService,
    private es: EventconfigStoreService,
    private logger: LogService
  ) {}

  ngOnInit(): void {
    this.eLang = this.es.getActiveLang();
    this.eLangGlob = this.es.getLangGlobals();
    this.logger.log('Glob: ' + this.eLangGlob.translateSendBtn);
    this.dataInterval();
    this.dataIntervalTimer = setInterval(() => {
      this.dataInterval();
    }, 1000);
  }
  dataInterval() {
    this.eConfig = this.es.getStoredConfig();
    this.eStatus = this.es.getStoredStatus();
    if (this.eConfig.txtAdditional !== undefined) {
      this.eLangGlob.translateAdditionalLabel = this.eConfig.txtAdditional;
      if(this.eConfig.customPolicyAdditional_enabled !== false) {
        this.eLangGlob.translatePolicyAdditional = this.eConfig.policyAdditional;
      }
    }
    if (this.eConfig.txtName !== undefined) {
      this.eLangGlob.translateNameLabel = this.eConfig.txtName;
      if(this.eConfig.customPolicyName_enabled !== false) {
        this.eLangGlob.translatePolicyName = this.eConfig.policyName;
      }
    }
    if (this.eConfig.txtMessage !== undefined) {
      this.eLangGlob.translateMsgLabel = this.eConfig.txtMessage;
      if(this.eConfig.customPolicyMessage_enabled !== false) {
      this.eLangGlob.translatePolicyMessage = this.eConfig.policyMessage;
      }
    }
  }
  onSubmit() {
    this.logger.log('Execute ' + this.form.msgText);
    if (this.form.msgText !== '' && this.form.msgText !== undefined) {
      this.hintText = this.eLangGlob.translateqSuccess;
      this.showHint = true;
      this.showHintTimeout = setTimeout(() => {
        this.removeHint();
        this.form.msgText = '';
      }, 2000);
      this.qs.sendMsg(this.form).subscribe(
        (data) => {
          this.logger.log('Frage abgeschickt');
        },
        (err) => {
          this.errorMessage = err.error.message;
        }
      );
      this.qs.sendMsgNew(this.form).subscribe(
        (data) => {
          this.logger.log('Frage an neues System geschickt');
        },
        (err) => {
          this.errorMessage = err.error.message;
        }
      );
    } else {
      this.hintText = this.eLangGlob.translateqError;
      this.showHint = true;
      this.showHintTimeout = setTimeout(() => {
        this.removeHint();
        this.form.msgText = '';
      }, 3000);
    }
  }
  private removeHint() {
    this.showHint = false;
  }
  toggleUserQuestions() {
    this.showQuestionForm = this.showQuestionForm === 'out' ? 'in' : 'out';
  }
}
