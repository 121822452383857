<div
  *ngIf="!preAuth"
  class="ui active dimmer fullscreen"
>
  <div class="ui large text loader">Loading...</div>
</div>
<div *ngIf="showPassword">
  <div
    *ngIf="eConfig"
    class="main"
  >
    <!-------------------------- HEADER START -------------------------->
    <div class="hr_logos">
      <div class="hr_logo_left">
        <div [class.mainlogo]="eSetup.mainLogoPosition === 'left'">
          <div
            *ngIf="
              (eSetup.mainLogoPosition !== 'left' &&
                eSetup.secondLogoPosition !== 'left' &&
                eSetup.secondLogoPosition !== 'none') ||
              (eSetup.secondLogoPosition === 'none' && eSetup.mainLogoPosition === 'right')
            "
          >
            <div
              class="languages"
              *ngIf="eLang.length > 1"
            >
              <img
                [ngClass]="{ langActive: activeLang === 'de' }"
                (click)="switchLang('de')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_ger.png"
                class="img_lang"
                *ngIf="eLang.includes('de')"
              />
              <img
                [ngClass]="{ langActive: activeLang === 'en' }"
                (click)="switchLang('en')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_uk.png"
                class="img_lang"
                *ngIf="eLang.includes('en')"
              />
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'nl' }"
                  (click)="switchLang('nl')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_nl.png"
                  class="img_lang"
                  *ngIf="eLang.includes('nl')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'pt' }"
                  (click)="switchLang('pt')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_pt.png"
                  class="img_lang"
                  *ngIf="eLang.includes('pt')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'es' }"
                  (click)="switchLang('es')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_es.png"
                  class="img_lang"
                  *ngIf="eLang.includes('es')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'cn' }"
                  (click)="switchLang('cn')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_cn.png"
                  class="img_lang"
                  *ngIf="eLang.includes('cn')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'jp' }"
                  (click)="switchLang('jp')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_jp.png"
                  class="img_lang"
                  *ngIf="eLang.includes('jp')"
                />
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="eSetup.secondLogoPosition === 'left' && screenwidth >= 680; px">
          <img
            id="secondLogo"
            width="{{ eSetup.secondLogo.width }}"
            src="{{ eSetup.secondLogo.route }}"
          />
        </div>
      </div>
      <div class="hr_logo_center">
        <div [class.mainlogo]="eSetup.mainLogoPosition === 'middle'">
          <div
            *ngIf="
              eSetup.mainLogoPosition !== 'middle' &&
              eSetup.secondLogoPosition !== 'middle' &&
              eSetup.secondLogoPosition !== 'none'
            "
          >
            <div
              class="languages"
              *ngIf="eLang.length > 1"
            >
              <img
                [ngClass]="{ langActive: activeLang === 'de' }"
                (click)="switchLang('de')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_ger.png"
                class="img_lang"
                *ngIf="eLang.includes('de')"
              />
              <img
                [ngClass]="{ langActive: activeLang === 'en' }"
                (click)="switchLang('en')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_uk.png"
                class="img_lang"
                *ngIf="eLang.includes('en')"
              />
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'nl' }"
                  (click)="switchLang('nl')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_nl.png"
                  class="img_lang"
                  *ngIf="eLang.includes('nl')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'pt' }"
                  (click)="switchLang('pt')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_pt.png"
                  class="img_lang"
                  *ngIf="eLang.includes('pt')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'es' }"
                  (click)="switchLang('es')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_es.png"
                  class="img_lang"
                  *ngIf="eLang.includes('es')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'cn' }"
                  (click)="switchLang('cn')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_cn.png"
                  class="img_lang"
                  *ngIf="eLang.includes('cn')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'jp' }"
                  (click)="switchLang('jp')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_jp.png"
                  class="img_lang"
                  *ngIf="eLang.includes('jp')"
                />
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="eSetup.secondLogoPosition === 'middle' && screenwidth >= 680; px">
          <img
            id="secondLogo"
            width="{{ eSetup.secondLogo.width }}"
          />
        </div>
      </div>
      <div class="hr_logo_right">
        <div [class.mainlogo]="eSetup.mainLogoPosition === 'right'">
          <div
            *ngIf="
              (eSetup.mainLogoPosition !== 'right' &&
                eSetup.secondLogoPosition !== 'right' &&
                eSetup.secondLogoPosition !== 'none') ||
              (eSetup.secondLogoPosition === 'none' && eSetup.mainLogoPosition !== 'right')
            "
          >
            <div
              class="languages"
              *ngIf="eLang.length === 2"
            >
              <img
                (click)="switchLang('de')"
                [ngClass]="{ langActive: activeLang === 'de' }"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_ger.png"
                class="img_lang"
                *ngIf="eLang.includes('de')"
              />
              <img
                (click)="switchLang('en')"
                [ngClass]="{ langActive: activeLang === 'en' }"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_uk.png"
                class="img_lang"
                *ngIf="eLang.includes('en')"
              />
              <ng-container>
                <img
                  (click)="switchLang('nl')"
                  [ngClass]="{ langActive: activeLang === 'nl' }"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_nl.png"
                  class="img_lang"
                  *ngIf="eLang.includes('nl')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'pt' }"
                  (click)="switchLang('pt')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_pt.png"
                  class="img_lang"
                  *ngIf="eLang.includes('pt')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'es' }"
                  (click)="switchLang('es')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_es.png"
                  class="img_lang"
                  *ngIf="eLang.includes('es')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'cn' }"
                  (click)="switchLang('cn')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_cn.png"
                  class="img_lang"
                  *ngIf="eLang.includes('cn')"
                />
              </ng-container>
              <ng-container>
                <img
                  [ngClass]="{ langActive: activeLang === 'jp' }"
                  (click)="switchLang('jp')"
                  src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_jp.png"
                  class="img_lang"
                  *ngIf="eLang.includes('jp')"
                />
              </ng-container>
            </div>
            <div
              *ngIf="eLang.length > 2"
              (clickOutside)="closeDown()"
              class="langDropDown"
            >
              <div
                class="activeEntry"
                [ngClass]="{ marginRight: mFromMediaLib }"
                (click)="toggleDown(true)"
              >
                {{ selectedLanguage }}
                <div
                  [ngClass]="{ rotateDown: down }"
                  class="dropDownArrow"
                ></div>
              </div>
              <div
                class="dropDown"
                [ngClass]="{ downActive: down }"
              >
                <div
                  *ngFor="let item of fullLanguage; let i = index"
                  class="entry"
                  (click)="switchLang(item.value)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="eSetup.secondLogoPosition === 'right' && screenwidth >= 680; px">
          <img
            id="secondLogo"
            width="{{ eSetup.secondLogo.width }}"
          />
        </div>
      </div>
    </div>
    <div class="hr_wrapper">
      <div
        class="row"
        style="padding-top: 0px"
      >
        <div class="seven wide column hr_headline">
          <h1 class="h1">{{ eConfig.title }}</h1>
          <h2 class="h2">{{ eConfig.subtitle }}</h2>
          <p><br /><br /></p>
        </div>
      </div>
      <div
        class="row"
        style="padding-top: 0px"
      >
        <lse-login></lse-login>
        <br /><br />
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="eConfig && pInit"
  class="main"
>
  <div
    [ngClass]="hamburger ? 'hamburgerActive' : 'hamburgerInactive'"
    class="hamburgerWindow"
  >
    <div
      (click)="closeBurger()"
      class="hamburgerDimmer"
    ></div>
    <div class="hamburgerContent">
      <div class="hamburger_headline">
        <h1 class="h1">{{ eConfig.title }}</h1>
      </div>
      <div class="hr_collapsible_description">
        <button
          type="button"
          (click)="toggleSidebarDescription()"
          class="hr_btn_collapsible entry_headline"
        >
          <span
            [ngClass]="{
              active: this.showSidebarDescription == 'in'
            }"
            class="arrow"
          >
            <span></span>
            <span></span>
          </span>
          <span> {{ eLangGlob.translateEventDescription }}</span>
        </button>
        <div
          [@slideInOut]="showSidebarDescription"
          class="eventDescription"
        >
          {{ eConfig.description }}
        </div>
      </div>
      <div
        class="languages"
        *ngIf="screenwidth <= 512 && eLang.length > 1"
      >
        <img
          [ngClass]="{ langActive: activeLang === 'de' }"
          (click)="switchLang('de')"
          src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_ger.png"
          class="img_lang"
          *ngIf="eLang.includes('de')"
        />
        <img
          [ngClass]="{ langActive: activeLang === 'en' }"
          (click)="switchLang('en')"
          src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_uk.png"
          class="img_lang"
          *ngIf="eLang.includes('en')"
        />
        <ng-container>
          <img
            [ngClass]="{ langActive: activeLang === 'nl' }"
            (click)="switchLang('nl')"
            src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_nl.png"
            class="img_lang"
            *ngIf="eLang.includes('nl')"
          />
        </ng-container>
        <ng-container>
          <img
            [ngClass]="{ langActive: activeLang === 'pt' }"
            (click)="switchLang('pt')"
            src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_pt.png"
            class="img_lang"
            *ngIf="eLang.includes('pt')"
          />
        </ng-container>
        <ng-container>
          <img
            [ngClass]="{ langActive: activeLang === 'es' }"
            (click)="switchLang('es')"
            src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_es.png"
            class="img_lang"
            *ngIf="eLang.includes('es')"
          />
        </ng-container>
        <ng-container>
          <img
            [ngClass]="{ langActive: activeLang === 'cn' }"
            (click)="switchLang('cn')"
            src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_cn.png"
            class="img_lang"
            *ngIf="eLang.includes('cn')"
          />
        </ng-container>
        <ng-container>
          <img
            [ngClass]="{ langActive: activeLang === 'jp' }"
            (click)="switchLang('jp')"
            src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_jp.png"
            class="img_lang"
            *ngIf="eLang.includes('jp')"
          />
        </ng-container>
      </div>
      <a
        href="{{ mBackLinkDecoded }}?ln={{ eLangUsed }}"
        class="media_library hamburger"
        *ngIf="screenwidth <= 1280"
      >
        <div class="media_library_arrow_left"></div>
        <span class="media_library_text">{{ eLangGlob.translateMediaLibraryLong }}</span>
      </a>

      <div class="moreEvents">{{ eLangGlob.translateFurtherEvents }}</div>
      <ng-container *ngFor="let thistoken of mediaLibToken">
        <div
          class="entry"
          *ngIf="thistoken.lang.includes(eLangUsed)"
        >
          <!-- <a
            *ngIf="this.eTrackerCookies === 'true' || this.eTrackerCookies === 'false'"
            href="{{ environmentVars.apiUrl }}{{ environmentVars.eventPrefix }}{{ thistoken.token }}?ln={{
              eLangUsed
            }}&medialib={{ mToken }}&back={{ mBackLinkEncoded }}&eTracker={{eTrackerCode}}&eTrackerCookies={{eTrackerCookies}}"
          > -->
          <a
            *ngIf="this.eTrackerCode"
            href="{{ environmentVars.apiUrl }}{{ environmentVars.eventPrefix }}{{ thistoken.token }}?ln={{
              eLangUsed
            }}&medialib={{ mToken }}&back={{ mBackLinkEncoded }}&eTracker={{eTrackerCode}}&eTrackerCookies={{eTrackerCookies}}"
          >
            <ng-container *ngIf="eLangUsed == 'de'">
              <span
                [ngClass]="{
                  activeEvent: url == thistoken.token
                }"
              >
                {{ thistoken.title_de }}
              </span>
            </ng-container>
            <ng-container *ngIf="eLangUsed == 'en'">
              <span
                [ngClass]="{
                  activeEvent: url == thistoken.token
                }"
                >{{ thistoken.title_en }}</span
              >
            </ng-container>
            <ng-container *ngIf="eLangUsed == 'nl'">
              <span
                [ngClass]="{
                  activeEvent: url == thistoken.token
                }"
              >
                {{ thistoken.title_nl }}
              </span>
            </ng-container>
            <ng-container *ngIf="eLangUsed == 'es'">
              <span
                [ngClass]="{
                  activeEvent: url == thistoken.token
                }"
              >
                {{ thistoken.title_es }}
              </span>
            </ng-container>
            <ng-container *ngIf="eLangUsed == 'pt'">
              <span
                [ngClass]="{
                  activeEvent: url == thistoken.token
                }"
              >
                {{ thistoken.title_pt }}
              </span>
            </ng-container>
            <ng-container *ngIf="eLangUsed == 'cn'">
              <span
                [ngClass]="{
                  activeEvent: url == thistoken.token
                }"
              >
                {{ thistoken.title_cn }}
              </span>
            </ng-container>
            <ng-container *ngIf="eLangUsed == 'jp'">
              <span
                [ngClass]="{
                  activeEvent: url == thistoken.token
                }"
              >
                {{ thistoken.title_jp }}
              </span>
            </ng-container>
          </a>
        </div>
      </ng-container>
      <div class="logout">
        {{ eLangGlob.translateBtnLogout }}
        <i
          class="sign out alternative icon"
          (click)="logout()"
        ></i>
      </div>
    </div>
  </div>
  <div
    class="hr_logos"
    [ngClass]="{ hr_logos_fixed: hamburger }"
    id="hr_logos"
  >
    <div class="hr_logo_left">
      <div [class.mainlogo]="eSetup.mainLogoPosition === 'left'">
        <div
          class="headerControl"
          *ngIf="
            (eSetup.mainLogoPosition !== 'left' &&
              eSetup.secondLogoPosition !== 'left' &&
              eSetup.secondLogoPosition !== 'none') ||
            (eSetup.secondLogoPosition === 'none' && eSetup.mainLogoPosition === 'right')
          "
        >
          <div
            *ngIf="this.mFromMediaLib"
            (click)="toggleBurger()"
            [ngClass]="{ active: hamburger }"
            class="hamburgerNav leftSide"
          >
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>
          <div
            class="languages"
            *ngIf="eLang.length === 2"
          >
            <img
              [ngClass]="{ langActive: activeLang === 'de' }"
              (click)="switchLang('de')"
              src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_ger.png"
              class="img_lang"
              *ngIf="eLang.includes('de')"
            />
            <img
              [ngClass]="{ langActive: activeLang === 'en' }"
              (click)="switchLang('en')"
              src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_uk.png"
              class="img_lang"
              *ngIf="eLang.includes('en')"
            />
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'nl' }"
                (click)="switchLang('nl')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_nl.png"
                class="img_lang"
                *ngIf="eLang.includes('nl')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'pt' }"
                (click)="switchLang('pt')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_pt.png"
                class="img_lang"
                *ngIf="eLang.includes('pt')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'es' }"
                (click)="switchLang('es')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_es.png"
                class="img_lang"
                *ngIf="eLang.includes('es')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'cn' }"
                (click)="switchLang('cn')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_cn.png"
                class="img_lang"
                *ngIf="eLang.includes('cn')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'jp' }"
                (click)="switchLang('jp')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_jp.png"
                class="img_lang"
                *ngIf="eLang.includes('jp')"
              />
            </ng-container>
          </div>
          <!-- more than two languages begin -->
          <div
            (clickOutside)="closeDown()"
            *ngIf="(eLang.length > 2 && screenwidth > 512) || (!mFromMediaLib && eLang.length > 2)"
            class="langDropDown"
          >
            <div
              class="activeEntry"
              [ngClass]="{ marginRight: mFromMediaLib }"
              (click)="toggleDown(true)"
            >
              {{ selectedLanguage }}
              <div
                [ngClass]="{ rotateDown: down }"
                class="dropDownArrow"
              ></div>
            </div>
            <div
              class="dropDown"
              [ngClass]="{ downActive: down }"
            >
              <div
                *ngFor="let item of fullLanguage; let i = index"
                class="entry"
                (click)="switchLang(item.value)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <!-- more than two languages end -->
          <a
            href="{{ mBackLinkDecoded }}?ln={{ eLangUsed }}"
            class="media_library"
            *ngIf="screenwidth > 1280 && this.mFromMediaLib"
          >
            <div class="media_library_arrow_left"></div>
            <span class="media_library_text">{{ eLangGlob.translateMediaLibraryShort }}</span>
          </a>
        </div>
      </div>
      <div *ngIf="eSetup.secondLogoPosition === 'left' && screenwidth >= 680; px">
        <img
          id="secondLogo"
          width="{{ eSetup.secondLogo.width }}"
          src="{{ eSetup.secondLogo.route }}"
        />
      </div>
    </div>
    <div class="hr_logo_center">
      <div [class.mainlogo]="eSetup.mainLogoPosition === 'middle'">
        <div
          *ngIf="
            eSetup.mainLogoPosition !== 'middle' &&
            eSetup.secondLogoPosition !== 'middle' &&
            eSetup.secondLogoPosition !== 'none'
          "
        >
          <div
            class="languages"
            *ngIf="eLang.length > 1"
          >
            <img
              [ngClass]="{ langActive: activeLang === 'de' }"
              (click)="switchLang('de')"
              src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_ger.png"
              class="img_lang"
              *ngIf="eLang.includes('de')"
            />
            <img
              [ngClass]="{ langActive: activeLang === 'en' }"
              (click)="switchLang('en')"
              src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_uk.png"
              class="img_lang"
              *ngIf="eLang.includes('en')"
            />
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'nl' }"
                (click)="switchLang('nl')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_nl.png"
                class="img_lang"
                *ngIf="eLang.includes('nl')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'pt' }"
                (click)="switchLang('pt')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_pt.png"
                class="img_lang"
                *ngIf="eLang.includes('pt')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'es' }"
                (click)="switchLang('es')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_es.png"
                class="img_lang"
                *ngIf="eLang.includes('es')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'cn' }"
                (click)="switchLang('cn')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_cn.png"
                class="img_lang"
                *ngIf="eLang.includes('cn')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'jp' }"
                (click)="switchLang('jp')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_jp.png"
                class="img_lang"
                *ngIf="eLang.includes('jp')"
              />
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="eSetup.secondLogoPosition === 'middle' && screenwidth >= 680; px">
        <img
          id="secondLogo"
          width="{{ eSetup.secondLogo.width }}"
        />
      </div>
    </div>
    <div class="hr_logo_right">
      <div [class.mainlogo]="eSetup.mainLogoPosition === 'right'">
        <div
          class="headerControl"
          *ngIf="
            (eSetup.mainLogoPosition !== 'right' &&
              eSetup.secondLogoPosition !== 'right' &&
              eSetup.secondLogoPosition !== 'none') ||
            (eSetup.secondLogoPosition === 'none' && eSetup.mainLogoPosition !== 'right')
          "
        >
          <a
            href="{{ mBackLinkDecoded }}?ln={{ eLangUsed }}"
            class="media_library"
            *ngIf="screenwidth > 1280 && this.mFromMediaLib"
          >
            <div class="media_library_arrow_left"></div>
            <span class="media_library_text">{{ eLangGlob.translateMediaLibraryShort }}</span>
          </a>
          <!-- Two Languages start -->
          <div
            class="languages"
            *ngIf="(eLang.length === 2 && screenwidth > 512) || (!mFromMediaLib && eLang.length === 2)"
          >
            <img
              [ngClass]="{ langActive: activeLang === 'de' }"
              (click)="switchLang('de')"
              src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_ger.png"
              class="img_lang"
              *ngIf="eLang.includes('de')"
            />
            <img
              [ngClass]="{ langActive: activeLang === 'en' }"
              (click)="switchLang('en')"
              src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_uk.png"
              class="img_lang"
              *ngIf="eLang.includes('en')"
            />
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'nl' }"
                (click)="switchLang('nl')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_nl.png"
                class="img_lang"
                *ngIf="eLang.includes('nl')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'pt' }"
                (click)="switchLang('pt')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_pt.png"
                class="img_lang"
                *ngIf="eLang.includes('pt')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'es' }"
                (click)="switchLang('es')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_es.png"
                class="img_lang"
                *ngIf="eLang.includes('es')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'cn' }"
                (click)="switchLang('cn')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_cn.png"
                class="img_lang"
                *ngIf="eLang.includes('cn')"
              />
            </ng-container>
            <ng-container>
              <img
                [ngClass]="{ langActive: activeLang === 'jp' }"
                (click)="switchLang('jp')"
                src="{{ uiAssetsPrefix }}assets/images/languagebuttons/btn_jp.png"
                class="img_lang"
                *ngIf="eLang.includes('jp')"
              />
            </ng-container>
          </div>
          <!-- two languages end -->
          <!-- more than two languages begin -->
          <div
            (clickOutside)="closeDown()"
            *ngIf="(screenwidth > 512 && eLang.length > 2) || (!mFromMediaLib && eLang.length > 2)"
            class="langDropDown"
          >
            <div *ngIf="screenwidth >= 512; else mobileDropDown">
              <div
                class="activeEntry"
                (click)="toggleDown(true)"
              >
                {{ selectedLanguage }}
                <div
                  [ngClass]="{ rotateDown: down }"
                  class="dropDownArrow"
                ></div>
              </div>
              <div
                class="dropDown"
                [ngClass]="{ downActive: down }"
              >
                <div
                  *ngFor="let item of fullLanguage; let i = index"
                  class="entry"
                  (click)="switchLang(item.value)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <ng-template #mobileDropDown>
            <div
              class="langHamburger hamburgerNav"
              (click)="toggleHamburger()"
              [ngClass]="{ active: showHamburger }"
              (clickOutside)="onClickedOutsideToggleHamburger($event)"
            >
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
              <div
                class="hoverBox"
                [@hoverInOut]="showHamburger ? 'open' : 'closed'"
                [class]="{ hamburgerHover: showHamburger }"
              >
                <div
                  *ngFor="let item of fullLanguage"
                  (click)="switchLang(item.value)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </ng-template>
          <!-- more than two languages end -->
          <div
            *ngIf="this.mFromMediaLib"
            (click)="toggleBurger()"
            [ngClass]="{ active: hamburger }"
            class="hamburgerNav"
          >
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>
        </div>
      </div>
      <div *ngIf="eSetup.secondLogoPosition === 'right' && screenwidth >= 680; px">
        <img
          id="secondLogo"
          src="{{ eSetup.secondLogo.route }}"
        />
      </div>
    </div>
  </div>
  <!-------------------------- HEADER END -------------------------->
  <div
    id="hr_wrapper"
    [ngClass]="{ hr_logos_top_spacer: hamburger }"
    class="hr_wrapper"
  >
    <div
      *ngIf="screenwidth > 1023; else header_mobile"
      class="hr_grid ui grid content_header"
    >
      <div class="row row_contentheader">
        <div class="seven wide column hr_headline left_side_margin_top">
          <h1 class="h1">{{ eConfig.title }}</h1>
          <h2 class="h2">{{ eConfig.subtitle }}</h2>
        </div>
        <div class="two wide column"></div>
        <div class="seven wide column hr_slides_vod">
          <!-- Post-Live VOD Controls-->
          <div
            class="vod_buttons"
            *ngIf="
              (eStatus.user_vote_questions || eStatus.user_ask_questions) && eStatus.slides && eStatus.player;
              else vod_buttons_placeholder
            "
          >
            <span
              [ngClass]="{ vod_buttons_switch_clicked: showSlides }"
              *ngIf="
                (eStatus.slides && eStatus.user_ask_questions && eStatus.phase === 'post-live') ||
                (eStatus.phase === 'live' && eStatus.slides && eStatus.user_vote_questions)
              "
              class="btn_switch_left"
            ></span>
            <input
              [ngClass]="{ vod_buttons_switch_clicked: showSlides }"
              *ngIf="
                (eStatus.slides && eStatus.user_ask_questions && eStatus.phase === 'post-live') ||
                (eStatus.phase === 'live' && eStatus.slides && eStatus.user_vote_questions)
              "
              type="button"
              class="vod_buttons_switch btn_show_presentation"
              value="{{ eLangGlob.translateBtnPresentation }}"
              (click)="showElement('slides')"
            />
            <span
              [ngClass]="{ vod_buttons_switch_clicked: showSlides }"
              *ngIf="
                (eStatus.slides && eStatus.user_ask_questions && eStatus.phase === 'post-live') ||
                (eStatus.phase === 'live' && eStatus.slides && eStatus.user_vote_questions)
              "
              class="btn_switch_right"
            ></span>
            <span
              [ngClass]="{ vod_buttons_switch_clicked: showQuestions }"
              *ngIf="
                (eStatus.slides && eStatus.user_ask_questions && eStatus.phase === 'post-live') ||
                (eStatus.phase === 'live' && eStatus.slides && eStatus.user_vote_questions)
              "
              class="btn_switch_left"
            ></span>
            <input
              type="button"
              [ngClass]="{ vod_buttons_switch_clicked: showQuestions }"
              *ngIf="
                (eStatus.slides &&
                  eStatus.user_ask_questions &&
                  eStatus.phase === 'post-live' &&
                  (!eSetup.questionOrChapter || eSetup.questionOrChapter === undefined)) ||
                (eStatus.phase === 'live' &&
                  eStatus.slides &&
                  eStatus.user_vote_questions &&
                  (!eSetup.questionOrChapter || eSetup.questionOrChapter === undefined))
              "
              class="vod_buttons_switch btn_show_questions"
              value="{{ eLangGlob.translateQuestions }}"
              (click)="showElement('questions')"
            />
            <input
              type="button"
              [ngClass]="{ vod_buttons_switch_clicked: showQuestions }"
              *ngIf="
                (eStatus.slides &&
                  eStatus.user_ask_questions &&
                  eStatus.phase === 'post-live' &&
                  eSetup.questionOrChapter) ||
                (eStatus.phase === 'live' && eStatus.slides && eStatus.user_vote_questions && eSetup.questionOrChapter)
              "
              class="vod_buttons_switch btn_show_questions"
              value="{{ eLangGlob.translateChapter }}"
              (click)="showElement('questions')"
            />

            <span
              [ngClass]="{ vod_buttons_switch_clicked: showQuestions }"
              *ngIf="
                (eStatus.slides && eStatus.user_ask_questions && eStatus.phase === 'post-live') ||
                (eStatus.phase === 'live' && eStatus.slides && eStatus.user_vote_questions)
              "
              class="btn_switch_right"
            ></span>
          </div>
          <ng-template #vod_buttons_placeholder>
            <div class="vod_buttons"></div>
          </ng-template>
          <div
            class="vod_nav_questions"
            *ngIf="eStatus.phase === 'post-live' && eStatus.player"
          >
            <div [hidden]="!showQuestions">
              <div
                class="vod_nav_control"
                *ngIf="vodCount > eSetup.vod_pageSize"
              >
                <div
                  class="vod_nav_buttons vod_nav_buttons_padding_left"
                  (click)="paginateVodList('minus')"
                >
                  <span class="arrowLeft"></span>

                  {{ eSetup.vod_pageSize }}
                </div>
                <span class="fragen1bis10">
                  <span *ngIf="!eSetup.questionOrChapter || eSetup.questionOrChapter === undefined">{{
                    eLangGlob.translateQuestions
                  }}</span>
                  <span *ngIf="eSetup.questionOrChapter">{{ eLangGlob.translateChapter }}</span>

                  {{ vodList?.vodFrom }}
                  {{ eLangGlob.translateTo }}
                  <span *ngIf="vodList?.vodTo < vodCount">{{ vodList?.vodTo }}</span>
                  <span *ngIf="vodList?.vodTo >= vodCount">{{ vodCount }}</span>
                </span>
                <div
                  class="vod_nav_buttons"
                  (click)="paginateVodList('plus')"
                >
                  {{ eSetup.vod_pageSize }}
                  <span class="arrowRight"></span>
                </div>
                <i
                  title="{{ eLangGlob.translateSyncDesctiption }}"
                  *ngIf="vodList?.manualPageChange"
                  (click)="vodList.changeManualPageChangeFalse()"
                  class="sync alternate icon"
                ></i>
              </div>
            </div>
            <div [hidden]="!showQuestions">
              <span class="hr_questions_total">
                <span *ngIf="!eSetup.questionOrChapter || eSetup.questionOrChapter === undefined"
                  >{{ eLangGlob.translateQuestions }}
                </span>
                <span *ngIf="eSetup.questionOrChapter">{{ eLangGlob.translateChapter }} </span>
                &nbsp;{{ eLangGlob.translateTotal }}: {{ vodCount }}
              </span>
            </div>
          </div>
          <div
            class="hr_emergency"
            *ngIf="eStatus.phase !== 'post-live' && eStatus.emergencyPublish"
          >
            <!--Wenn Notfalltext--><span>{{ eEmergency }}</span>
          </div>
        </div>
      </div>
    </div>
    <ng-template #header_mobile>
      <div class="hr_headline">
        <h1 class="h1">{{ eConfig.title }}</h1>
        <h2 class="h2">{{ eConfig.subtitle }}</h2>
      </div>
    </ng-template>
    <div *ngIf="screenwidth <= 1023 && eStatus.phase !== 'post-live' && eStatus.emergencyPublish">
      <div class="hr_emergency_mobile">
        <!--Wenn Notfalltext--><span>{{ eEmergency }}</span>
      </div>
    </div>
    <div
      class="hr_grid ui stackable grid onlyPlayer"
      *ngIf="eStatus && eStatus.isLive && isAuthenticated && !eStatus.slides && !eStatus.user_ask_questions"
    >
      <div class="fullPlayer">
        <lse-videoplayer
          [stream]="this.eStreamSrc"
          [hidden]="eStatus.phase == 'pre-live' || (eStatus.phase === 'post-live' && !eStatus.player)"
          [eStatus]="eStatus"
          #player
          [@inOutAnimation]
          [options]="{
            fluid: true,
            controls: true,
            autoplay: true,
            playbackRates: usePlaybackrates,
            liveui: true,
            poster: this.posterPic,
            sources: [{ src: eStream, type: 'application/x-mpegURL' }]
          }"
        ></lse-videoplayer>
        <div
          class="hr_wartediv"
          *ngIf="eStatus.phase == 'pre-live' || (eStatus.phase === 'post-live' && !eStatus.player)"
        >
          <h2
            *ngIf="eStatus.use_postlive_txt"
            class="hr_wartetext"
          >
            {{ eConfig.postlive_txt }}
          </h2>
          <h2
            *ngIf="!eStatus.use_postlive_txt"
            class="hr_wartetext"
          >
            {{ eConfig.waiting_txt }}
          </h2>
        </div>
      </div>

      <!-- PODIUM -->
      <div class="hr_podium">
        <div
          (click)="togglePodium()"
          class="hr_collapsible_podium"
          *ngIf="eStatus.user_vote_questions || (screenwidth <= 1023 && eConfig.participants.length !== 0)"
        >
          <button
            type="button"
            class="hr_btn_collapsible"
          >
            <span
              [ngClass]="{
                active: this.showPodium == 'in'
              }"
              class="arrow"
            >
              <span></span>
              <span></span>
            </span>
            <span> {{ eLangGlob.translateParticipants }}</span>
          </button>
        </div>
        <div
          class="hr_collapsed_podium"
          [@slideInOut]="showPodium"
          *ngIf="eConfig.participants.length !== 0"
        >
          <div
            [ngClass]="{
              margintop: eStatus.user_vote_questions && screenwidth <= 1023 && eConfig.participants.length !== 0
            }"
          >
            <ng-container *ngFor="let author of eConfig.participants">
              <h4>
                <span class="name">{{ author['name'] }}</span>
                <span *ngIf="screenwidth > 512 && author['function'] != ''; else podiumDelimiter">, </span>
                <span class="function">{{ author['function'] }}</span>
              </h4>
              <ng-template #podiumDelimiter>
                <span *ngIf="author['function'] != ''">,<br /></span>
              </ng-template>
            </ng-container>
          </div>
        </div>
        <hr *ngIf="eStatus.user_vote_questions || (screenwidth <= 1023 && eConfig.participants.length !== 0)" />
      </div>
      <!-- PODIUM ENDE -->

    </div>

    <div
      class="hr_grid ui stackable grid"
      *ngIf="eStatus && eStatus.isLive && isAuthenticated && (eStatus.slides || eStatus.user_ask_questions)"
    >
      <div class="row">
        <div class="left seven wide column">
          <!-- PLAYER -->
          <div class="box leftBox">
            <lse-videoplayer
              [stream]="this.eStreamSrc"
              [hidden]="eStatus.phase === 'pre-live' || (!eStatus.player && eStatus.phase === 'post-live')"
              [eStatus]="eStatus"
              #player
              [@inOutAnimation]
              [options]="{
                fluid: true,
                controls: true,
                autoplay: true,
                playbackRates: usePlaybackrates,
                liveui: true,
                poster: this.posterPic,
                sources: [{ src: eStream, type: 'application/x-mpegURL' }]
              }"
            ></lse-videoplayer>
            <div
              class="hr_wartediv"
              *ngIf="eStatus.phase === 'pre-live' || (!eStatus.player && eStatus.phase === 'post-live')"
            >
              <h2
                *ngIf="eStatus.use_postlive_txt"
                class="hr_wartetext"
              >
                {{ eConfig.postlive_txt }}
              </h2>
              <h2
                *ngIf="!eStatus.use_postlive_txt"
                class="hr_wartetext"
              >
                {{ eConfig.waiting_txt }}
              </h2>
            </div>
          </div>
          <!-- PLAYER ENDE -->
          <!-- PODIUM -->
          <div class="hr_podium">
            <div
              (click)="togglePodium()"
              class="hr_collapsible_podium"
              *ngIf="eStatus.user_vote_questions || (screenwidth <= 1023 && eConfig.participants.length !== 0)"
            >
              <button
                type="button"
                class="hr_btn_collapsible"
              >
                <span
                  [ngClass]="{
                    active: this.showPodium == 'in'
                  }"
                  class="arrow"
                >
                  <span></span>
                  <span></span>
                </span>
                <span> {{ eLangGlob.translateParticipants }}</span>
              </button>
            </div>
          </div>

          <div
            class="hr_collapsed_podium"
            [@slideInOut]="showPodium"
            *ngIf="eConfig.participants.length !== 0"
          >
            <div
              [ngClass]="{
                margintop: eStatus.user_vote_questions && screenwidth <= 1023 && eConfig.participants.length !== 0
              }"
            >
              <ng-container *ngFor="let author of eConfig.participants | slice:0: !showAllParticipants && screenwidth > 1023 ? 5 : undefined">
                <h4>
                  <span class="name">{{ author['name'] }}</span>
                  <span *ngIf="screenwidth > 512 && author['function'] != ''; else podiumDelimiter">, </span>
                  <span class="function">{{ author['function'] }}</span>
                </h4>
                <ng-template #podiumDelimiter>
                  <span *ngIf="author['function'] != ''">,<br /></span>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="screenwidth > 1023">
                <span  class="selectable smallFont gray" *ngIf="eConfig.participants.length > 5 && !showAllParticipants"  (click)="showAllParticipants = true">{{ eLangGlob.translateShowMore }}</span>
                <span class="selectable smallFont gray" *ngIf="eConfig.participants.length > 5 && showAllParticipants" (click)="showAllParticipants =false"  >{{ eLangGlob.translateShowLess }}</span>
              </ng-container>
            </div>
          </div>
          <hr *ngIf="eConfig.description_enabled && eConfig.participants.length !== 0 || (screenwidth <= 1023 && eConfig.description_enabled !== true)" />
            <!-- PODIUM ENDE -->
            <!-- DESCRIPTION START -->
            <div class="hr_podium" style='margin-top: unset !important;' *ngIf="eConfig.description_enabled" >
              <div
                (click)="toggleEventDescription()"
                class="hr_collapsible_podium"
              >
                <button
                  type="button"
                  class="hr_btn_collapsible"
                >
                <span
                  [ngClass]="{
                    active: this.showEventDescription == 'in'
                  }"
                  class="arrow"
                >
                  <span></span>
                  <span></span>
                </span>
                  <span> {{ eLangGlob.translateEventDescription }}</span>
                </button>
              </div>
            </div>
            <div
              class="hr_collapsed_podium"
              [@slideInOut]="showEventDescription"
              *ngIf="eConfig.description_enabled === true"
            >
              <div
                [ngClass]="{
                margintop: eConfig.description_enabled || (screenwidth <= 1023 && eConfig.description_enabled === true)
              }"
              >
                <lse-description
                  [description]="eConfig.description"
                >
                </lse-description>
              </div>
          </div>
          <hr *ngIf="eConfig.description_enabled  || (eConfig.description_enabled === true && screenwidth <= 1023)" />


          <!-- DESCRIPTION ENDE -->

          <!-- MOBILE DIE RESTLICHEN KOMPONENTEN -->
          <div *ngIf="screenwidth <= 1023">
            <div
              class="hr_slides_mobile"
              *ngIf="eStatus.slides"
            >
              <div
                class="hr_collapsible_slides"
                (click)="toggleSlideMobile()"
              >
                <button
                  type="button"
                  class="hr_btn_collapsible"
                >
                  <span
                    [ngClass]="{
                      active: this.showSlideMobile == 'in'
                    }"
                    class="arrow"
                  >
                    <span></span>
                    <span></span>
                  </span>
                  <span>{{ eLangGlob.translateSlide }}</span>
                </button>
              </div>
              <div
                class="hr_collapsed_slides"
                [@slideInOut]="showSlideMobile"
              >
                <div style="padding-top: 20px">
                  <lse-slide
                    *ngIf="eStatus.slides && eStatus.isLive && eActiveSlide && slideLoadComplete"
                    [slide]="eActiveSlide"
                    [path]="eSlidePath"
                  ></lse-slide>
                </div>
                <div class="hr_dropdown_slides">
                  <div
                    class="hr_current_slide"
                    *ngIf="eStatus.slideflow && eStatus.phase !== 'post-live'"
                  >
                    <span
                      class="cur_slide_left"
                      [ngClass]="{
                        cur_slide_left_disabled: !this.slideIsCustom
                      }"
                    ></span>
                    <button
                      class="btn_current_slide"
                      [ngClass]="{
                        btn_current_slide_disabled: !this.slideIsCustom
                      }"
                      (click)="myReturnToLiveSlide()"
                    >
                      <span>{{ eLangGlob.translateCurSlide }}</span>
                    </button>
                    <span
                      class="cur_slide_right"
                      [ngClass]="{
                        cur_slide_right_disabled: !this.slideIsCustom
                      }"
                    ></span>
                  </div>
                </div>
                <div
                  *ngIf="
                    (eStatus.slideflow && eStatus.phase !== 'post-live') ||
                    (eStatus.player && eStatus.phase === 'post-live' && eStatus.slideflow)
                  "
                  class="hr_collapsed_slides"
                >
                  <lse-slideflow-list
                    (playAtEvent)="player.playAt($event)"
                    (showElement)="showElement('slides')"
                    [playlist]="playlist"
                    [currentSlide]="eActiveSlide"
                    [phase]="eStatus.phase"
                    [eStatus]="eStatus"
                    [eLangGlob]="eLangGlob"
                    [slideIsCustom]="slideIsCustom"
                    (myReturnToLiveSlide)="myReturnToLiveSlide()"
                    #slideHistoryChild
                  ></lse-slideflow-list>
                </div>
              </div>
            </div>
            <hr *ngIf="eStatus.slides" />
<!--            <lse-user-strawpoll-->
<!--              *ngIf="eStatus.user_strawpoll"-->
<!--              [eLangGlob]="eLangGlob"-->
<!--              [eStatus]="eStatus"-->
<!--            ></lse-user-strawpoll>-->
            <lse-user-vote-question-list
              *ngIf="eStatus.user_vote_questions && eStatus.phase !== 'post-live'"
              [pageSize]="eSetup.vod_pageSize"
              [eStatus]="eStatus"
              (playAtEvent)="player.playAt($event)"
            ></lse-user-vote-question-list>
            <lse-vod-question-list
              *ngIf="
                !eStatus.user_vote_questions &&
                eStatus.user_ask_questions &&
                screenwidth > 512 &&
                eStatus.phase === 'post-live' &&
                eStatus.player
              "
              [pageSize]="eSetup.vod_pageSize"
              (playAtEvent)="player.playAt($event)"
            ></lse-vod-question-list>
            <lse-vod-question-list
              *ngIf="
                !eStatus.user_vote_questions &&
                eStatus.user_ask_questions &&
                screenwidth <= 512 &&
                eStatus.phase === 'post-live' &&
                eStatus.player
              "
              [pageSize]="1"
              (playAtEvent)="player.playAt($event)"
            ></lse-vod-question-list>
            <!-- UAQ -->
            <lse-user-ask-question
              *ngIf="(eStatus.user_ask_questions && screenwidth <= 1023) || eStatus.user_vote_questions"
            ></lse-user-ask-question>
            <!-- UAQ ENDE -->
          </div>
          <!-- MOBILE DIE RESTLICHEN KOMPONENTEN ENDE -->
        </div>
        <div
          *ngIf="screenwidth > 1023"
          class="middle two wide column"
        ></div>
        <!-- Laptopgröße und größer: ALLES AB 1023px -->
        <div
          *ngIf="screenwidth > 1023"
          class="right seven wide column"
        >
          <!-- VOD (Post Live)-->
          <div *ngIf="eStatus.phase === 'post-live'">
            <!-- VOD QUESTION LISTE ODER SLIDE-->
            <div
              *ngIf="
                eStatus.isLive &&
                eStatus.user_ask_questions &&
                showQuestions &&
                eStatus.phase === 'post-live' &&
                eStatus.player
              "
              class="box rightBox overflow"
            >
              <lse-vod-question-list
                [pageSize]="eSetup.vod_pageSize"
                (playAtEvent)="player.playAt($event)"
                (questionCount)="questionCount($event)"
              ></lse-vod-question-list>
            </div>
            <!-- VOD QUESTION LISTE ENDE -->
            <!-- VOD SLIDE -->
            <div
              *ngIf="eStatus.isLive && eStatus.slides && showSlides && eStatus.phase == 'post-live'"
              class="box rightBox"
            >
              <lse-slide
                *ngIf="slideLoadComplete && eActiveSlide"
                [slide]="eActiveSlide"
                [path]="eSlidePath"
              ></lse-slide>
              <!-- VOD SLIDE ENDE -->
            </div>
            <lse-slideflow-list
              *ngIf="eStatus.slides && eStatus.slideflow && eStatus.player"
              (playAtEvent)="player.playAt($event)"
              (showElement)="showElement('slides')"
              [playlist]="playlist"
              [currentSlide]="eActiveSlide"
              [phase]="eStatus.phase"
              [eStatus]="eStatus"
              [eLangGlob]="eLangGlob"
              [slideIsCustom]="slideIsCustom"
              (myReturnToLiveSlide)="myReturnToLiveSlide()"
              #slideHistoryChild
            ></lse-slideflow-list>
          </div>
          <!-- (PRE-)Live -->
          <div *ngIf="eStatus.phase !== 'post-live' && eStatus.isLive">
            <!-- ------------------------------------------------------------------------ -->
            <!-- RECHTE SEITE BEGINNEND MIT SLIDES -->
            <div *ngIf="eStatus.slides && showSlides && slideLoadComplete">
              <div class="box rightBox">
                <lse-slide
                  *ngIf="eActiveSlide"
                  [slide]="eActiveSlide"
                  [path]="eSlidePath"
                ></lse-slide>
              </div>
              <!-- LIVE SLIDE HISTORY -->
              <lse-slideflow-list
                *ngIf="eStatus.slides && eStatus.slideflow"
                (playAtEvent)="player.playAt($event)"
                (showElement)="showElement('slides')"
                [playlist]="playlist"
                [currentSlide]="eActiveSlide"
                [phase]="eStatus.phase"
                [eStatus]="eStatus"
                [eLangGlob]="eLangGlob"
                [slideIsCustom]="slideIsCustom"
                (myReturnToLiveSlide)="myReturnToLiveSlide()"
                #slideHistoryChild
              ></lse-slideflow-list>
              <!-- LIVE SLIDE HISTORY ENDE -->
              <!-- LIVE UAQ -->
              <lse-user-ask-question
                *ngIf="eStatus.user_ask_questions && !eStatus.user_vote_questions"
              ></lse-user-ask-question>
              <!-- LIVE UAQ ENDE -->
              <!-- LIVE USER STRAWPOLL -->
<!--              <lse-user-strawpoll-->
<!--                [eLangGlob]="eLangGlob"-->
<!--                [eStatus]="eStatus"-->
<!--              ></lse-user-strawpoll>-->
              <!-- LIVE USER STRAWPOLL ENDE -->
            </div>
            <!-- RECHTE SEITE BEGINNEND MIT SLIDES ENDE -->
            <!-- ------------------------------------------------------------------------ -->
            <!-- RECHTE SEITE BEGINNEND MIT UAQ -->
            <div *ngIf="eStatus.user_ask_questions && !eStatus.slides && !eStatus.user_vote_questions">
              <div class="box rightBox">
                <lse-user-ask-question></lse-user-ask-question>
              </div>
              <!-- LIVE USER STRAWPOLL -->
<!--              <lse-user-strawpoll-->
<!--                [eLangGlob]="eLangGlob"-->
<!--                [eStatus]="eStatus"-->
<!--              ></lse-user-strawpoll>-->
              <!-- LIVE USER STRAWPOLL ENDE -->
            </div>
            <!-- RECHTE SEITE BEGINNEND MIT UAQ ENDE -->
            <!-- ------------------------------------------------------------------------ -->
            <!-- RECHTE SEITE BEGINNEND MIT UVQ -->
            <div *ngIf="eStatus.user_vote_questions && showQuestions">
              <lse-user-vote-question-list
                [pageSize]="eSetup.vod_pageSize"
                (playAtEvent)="player.playAt($event)"
              ></lse-user-vote-question-list>
            </div>
            <!-- RECHTE SEITE BEGINNEND MIT UVQ ENDE -->
          </div>
        </div>
      </div>
    </div>

    <ng-template #waittext>
      <div [@inOutAnimation]>
        <div
          class=""
          style="width: 780px; height: 440px; background-color: #3ed8c3; text-align: center"
        >
          <h3
            *ngIf="eStatus.use_postlive_txt"
            style="vertical-align: middle; line-height: 440px"
          >
            {{ eConfig.postlive_txt }}
          </h3>
          <h3
            *ngIf="!eStatus.use_postlive_txt"
            style="vertical-align: middle; line-height: 440px"
          >
            {{ eConfig.waiting_txt }}
          </h3>
        </div>
      </div>
    </ng-template>
  </div>
  <!---------------------- FOOTER BEGINN ------------------------>
  <div
    *ngIf="screenwidth > 1023; else footer_mobile"
    class="ui grid hr_bottom row"
  >
    <div class="adminNotices">
      <div
        class="debug"
        *ngIf="eStatus.debug_option"
      >
        <span>DEBUG:</span>
        <br />
        <span>Stream-Source: {{ eStream }}</span>
        <br />
        <span>Intranet-Status: {{ debugAssignServer }}</span>
      </div>
      <div
        class="server"
        *ngIf="eStatus.phase === 'live'"
      >
        {{ serverDisplay }}
      </div>
    </div>
    <div
      *ngIf="eOptionalTxt != '' && eOptionalTxt != undefined"
      class="row optional_link"
    >
      <div class="seven wide column">
        <span
          >{{ eOptionalTxt }}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="{{ eOptionalLink }}"
            >{{ eOptionalLinkDesc }}</a
          ></span
        >
      </div>
      <div class="two wide column"></div>
      <div class="seven wide column"></div>
    </div>
    <div class="row hr_footer">
      <div class="three wide column hr_policy">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="{{ environmentVars.apiUrl }}{{ environmentVars.eventPrefix }}misc/impressum.php?ln={{ eLangUsed }}"
          >{{ eLangGlob.translateImprint }}</a
        >
      </div>
      <div class="three wide column hr_legal_notice">
        <a
          *ngIf="environmentVars.customer!== 'rwe' else rweDataPolicy"
          target="_blank"
          rel="noopener noreferrer"
          href="{{ environmentVars.apiUrl }}{{ environmentVars.eventPrefix }}misc/datenschutz.php?ln={{ eLangUsed }}"
          >{{ eLangGlob.translateDatapolicy }}</a
        >
        <ng-template #rweDataPolicy>
          <a
            target="_blank"
            rel="noopener noreferrer"
            [href]="activeLang === 'de' ? 'https://www.rwe.com/datenschutz/konferenz/' : 'https://www.rwe.com/en/data-protection/conference/'"
          >{{ eLangGlob.translateDatapolicy }}
          </a>
        </ng-template>
      </div>
      <div class="three wide column hr_tech_info">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="{{ environmentVars.apiUrl }}{{ environmentVars.eventPrefix }}misc/technik.php?ln={{ eLangUsed }}"
        >
          {{ eLangGlob.translateTechInfo }}
        </a>
      </div>
      <div class="two wide column"></div>
      <div class="seven wide column">
        <div *ngIf="eStatus.phase == 'live' || eStatus.phase == 'pre-live'">
          <span class="hr_stoerung">{{ eLangGlob.translateSendmail }}: </span>
          <a
            class="hr_stoerung_link"
            href="mailto:{{ environmentVars.email }}?subject={{ eLangGlob.translateEmailSubject + mailSubjectServer }}"
          >
            {{ environmentVars.email }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <ng-template #footer_mobile>
    <div class="row ui grid hr_bottom">
      <div class="adminNotices">
        <div
          class="debug"
          *ngIf="eStatus.debug_option"
        >
          <span>DEBUG:</span>
          <br />
          <span>Stream-Source: {{ eStream }}</span>
          <br />
          <span>Intranet-Status: {{ debugAssignServer }}</span>
        </div>
        <div
          class="server"
          *ngIf="eStatus.phase === 'live'"
        >
          {{ serverDisplay }}
        </div>
      </div>
      <div
        *ngIf="eOptionalTxt != '' && eOptionalTxt != undefined"
        class="row optional_link"
      >
        <div class="sixteen wide column">
          <span
            >{{ eOptionalTxt }}
            <br *ngIf="screenwidth <= 512" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="{{ eOptionalLink }}"
              >{{ eOptionalLinkDesc }}</a
            ></span
          >
        </div>
      </div>
      <div
        class="row hr_footer"
        *ngIf="eStatus.phase == 'live' || eStatus.phase == 'pre-live'"
      >
        <div class="sixteen wide column">
          <span class="hr_stoerung">{{ eLangGlob.translateSendmail }}: </span>
          <a
            class="hr_stoerung_link"
            href="mailto:{{ environmentVars.email }}?subject={{ eLangGlob.translateEmailSubject + mailSubjectServer }}"
          >
            {{ environmentVars.email }}
          </a>
        </div>
      </div>
      <div class="row hr_links">
        <div
          class="sixteen wide column"
          style="display: flex; justify-content: space-between; align-items: center"
        >
          <div class="hr_policy">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="{{ environmentVars.apiUrl }}{{ environmentVars.eventPrefix }}misc/impressum.php?ln={{ eLangUsed }}"
            >
              {{ eLangGlob.translateImprint }}
            </a>
          </div>
          <div class="hr_legal_notice">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="{{ environmentVars.apiUrl }}{{ environmentVars.eventPrefix }}misc/datenschutz.php?ln={{
                eLangUsed
              }}"
            >
              {{ eLangGlob.translateDatapolicy }}
            </a>
          </div>
          <div class="hr_tech_info">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="{{ environmentVars.apiUrl }}{{ environmentVars.eventPrefix }}misc/technik.php?ln={{ eLangUsed }}"
            >
              {{ eLangGlob.translateTechInfo }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-------------------------- FOOTER ENDE -------------------------->
</div>
